/**
 * Prevent Angular change detection from running with certain callbacks
 */
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = [
    'requestAnimationFrame',
    'message',
    'scroll',
    'touchmove',
    'touchend',
    'mousemove',
    'mouseup',
    'pointermove',
    'pointercancel',
];
(window as any).__Zone_disable_requestAnimationFrame = true;
